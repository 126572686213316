<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2
      bannerH="设计师联盟"
      bannerP="专业有保障、服务更贴心"
      :bannerImg="require('@/assets/img/top_back.png')"
    ></banner2>
    <div class="main">
      <h1 class="box-title">为什么要来狸小住认证</h1>
      <div class="item flex flex-dr">
        <div class="items flex" v-for="(item, index) in list" :key="index">
          <div class="items-left">
            <div class="arc">
              <div class="round"></div>
            </div>
          </div>

          <div class="items-middle">
            <div class="middle-top flex">
              <div class="middle-number">0{{ index + 1 }}</div>
              <div class="middle-title">{{ item.title }}</div>
            </div>
            <div class="middle-bottom">
              <p>{{ item.content }}</p>
              <p v-if="item.content2">{{ item.content2 }}</p>
              <p v-if="item.content3">{{ item.content3 }}</p>
            </div>
          </div>

          <div class="items-right">
            <img
              class="img"
              :src="
                require(`@/assets/img/designerUnion/items_${index + 1}.png`)
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <BottomStepsFull title="认证步骤" :stepList="stepList"></BottomStepsFull>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import BottomStepsFull from "@/components/BottomStepsFull.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
    BottomStepsFull,
  },
  data() {
    return {
      list: [
        {
          title: "严格认证体系",
          content:
            "狸小住认证设计师，是国内行业领先的家居室内设计师圈 实名认证、公司资质、实景案例、完善的服务落地能力等多维度严格审核",
        },
        {
          title: "接单变得更简单",
          content:
            "专属认证标识，设计师100%收取设计费直接对接业主，90%认证设计师收到业主咨询1V1专业运营指导，高效运营工具铺助接单",
        },
        {
          title: "精准匹配目标用户",
          content:
            "本地专属找【 装修服务费 】列表展示大数据赋能设计师，多场景智能推荐多渠道内容强势曝光潜在客户",
        },
        {
          title: "打通设计 — 交付 — 管理",
          content:
            "一边修改、一边自动报价及生成清单，实时把控预算，提高客户信任感",
          content2: "设计师完成效果图，消费者查看方案确认下单",
          content3: "订单流程无变化，生成对接与保障",
        },
      ],
      stepList: [
        {
          top: "提交资料",
          bottom: "上传真实有效信息",
        },
        {
          top: "通过审核",
          bottom: "5个工作日完成审核",
        },
        {
          top: "认证成功",
          bottom: "成为设计师账号",
        },
      ],
    };
  },
  mounted() {
    this.stepList.forEach((_, index) => {
      this.$set(
        this.stepList[index],
        "icon",
        require(`@/assets/img/designerUnion/step_icon_${index + 1}.png`)
      );
    });
  },
};
</script>
<style scoped lang="scss">
div {
  box-sizing: border-box;
}
.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
.main {
  width: 1200px;
  margin: 40px auto;
  .box-title {
    text-align: center;
    font-weight: bold;
    font-size: 38px;
    color: #1f2329;
    margin-bottom: 40px;
  }
  .item {
    width: 100%;
    gap: 40px;
    .items {
      height: 340px;
      background-color: #f7f7ff;
      // height: 100%;
      .items-left {
        height: 100%;
        width: 242px;
        // background: red;
        overflow: hidden;
        .arc {
          box-sizing: content-box;
          position: relative;
          margin-left: -130px;
          margin-top: -130px;
          width: 120px;
          height: 120px;
          border: solid 80px #9bb0f8;
          // background: #9bb0f8;
          border-radius: 50%;
        }
        .round {
          box-sizing: content-box;
          position: absolute;
          right: -80px;
          bottom: -80px;
          border-radius: 50%;
          width: 75px;
          height: 75px;
          background: #5e81f4;
        }
      }
      .items-middle {
        width: 470px;
        height: 100%;
        color: #444444;
        padding-top: 83px;
        .middle-top {
          margin-bottom: 26px;
          .middle-number {
            font-size: 60px;
          }
          .middle-title {
            font-size: 22px;
            font-weight: 500;
            margin-top: 39px;
          }
        }
        .middle-bottom {
          width: 358px;
          font-weight: 500;
          font-size: 14px;
          color: #444444;
          white-space: wrap;
          line-height: 30px;
        }
      }
      .items-right {
        width: 488px;
        height: 100%;
        .img {
          margin-top: -60px;
        }
      }
    }
  }
}
</style>
